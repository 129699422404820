module.exports = {
  video : [
    "3gp",
    "avi",
    "mov",
    "mp4",
    "mpeg",
    "ogg",
    "ogv",
    "webm",
    "wmv",
  ],
  image : [
    "jpeg",
    "jpg",
    "png",
    "gif"
  ],
};